import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import api from "../../api/api.js";

export default function RawDataReport() {
  const presentDate = new Date();
  const formatPresentDate = `${presentDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${(presentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${presentDate.getFullYear()} ${presentDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${presentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${presentDate.getSeconds().toString().padStart(2, "0")}`;

    const downloadDate = `${presentDate.getDate().toString().padStart(2, "0")}-${(
      presentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${presentDate.getFullYear()}_${presentDate
      .getHours()
      .toString()
      .padStart(2, "0")}-${presentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}-${presentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}-${presentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0")}`;

    const replaceValue = (value) => {
      if (value === undefined || value === null) {
        return '';
      }
      if (value === '-999') {
        return '';
      }
      return value.replace(/\$/g, ', ');
    };


    const replaceNegativeWithValue = (value) => {
      // return value === '-999' ? '' : value;
      if (value === undefined || value === null) {
        return '';
      }
      if (value === '-999') {
        return '';
      }
      return value.replace(/\$/g, ', ');

  };

// const formatDate = (inputDate) => {
//   const dateObj = new Date(inputDate);
//   const day = dateObj.getDate().toString().padStart(2, '0');
//   const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
//   const year = dateObj.getFullYear().toString();
//   return `${day}-${month}-${year}`;
// };
const formatDate = (inputDate) => {
  const dateObj = new Date(inputDate);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear().toString();
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getSeconds().toString().padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

  const [loading, setLoading] = useState(false);

  const [tab1Data, setTab1Data] = useState([]);
  const [tab2Data, setTab2Data] = useState([]);
  const [tab3Data, setTab3Data] = useState([]);
  const [tab4Data, setTab4Data] = useState([]);
  const [tab5Data, setTab5Data] = useState([]);
  const [tab6Data, setTab6Data] = useState([]);
  const [tab7Data, setTab7Data] = useState([]);
  const [tab8Data, setTab8Data] = useState([]);
  const [tab9Data, setTab9Data] = useState([]);
  const [tab10Data, setTab10Data] = useState([]);
  const [tab11Data, setTab11Data] = useState([]);
  const toast = useRef(null);
  const userID = sessionStorage.getItem("UserID");

  const rawData = async () => {
    try {
      setLoading(true);
      const requestData = {
        login_user_id: userID,
        request_tables: {
          trn_tbl_hh_consent_form: "",
          trn_tbl_respondent_detail_form: "",
          trn_tbl_gi_house_hold_detail: "",
          trn_tbl_family_details: "",
		      trn_tbl_standred_of_living: "",
          trn_tbl_hospitalization: "",
          trn_tbl_chornic_illnesses: "",
          trn_tbl_hypertension: "",
          trn_tbl_diabets: "",
          trn_tbl_diet_and_nutrition: "",
          trn_tbl_health_problem: ""
        },
        synceddatetime: formatPresentDate,
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.get_Admin_RawData(requestData);
      const responseData = result.responsemessage;
      if (result && result.status === "1") {
      //Tab1
      setTab1Data(responseData.trn_tbl_hh_consent_form);
      const Tab1Slno = result.responsemessage.trn_tbl_hh_consent_form.map((role, index) => ({ ...role, slNo: index + 1, 
        fld_user_name:replaceValue(role.fld_user_name),
        fld_respondent_name:replaceValue(role.fld_respondent_name),
        fld_agreed_paricipate_survy_id:replaceValue(role.fld_agreed_paricipate_survy_id),
        fld_agreed_paricipate_survy_name:replaceValue(role.fld_agreed_paricipate_survy_name),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
      }));
      setTab1Data(Tab1Slno);
      //Tab2
      setTab2Data(responseData.trn_tbl_respondent_detail_form);
      const Tab2Slno = result.responsemessage.trn_tbl_respondent_detail_form.map((role, index) => ({ ...role, slNo: index + 1,
        fld_respondent_id:replaceValue(role.fld_respondent_id),
        fld_respondent_name:replaceValue(role.fld_respondent_name),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
        // fld_interviewer_name:replaceValue(role.fld_interviewer_name),
        fld_hh_number:replaceValue(role.fld_hh_number),
        fld_respondent_height:replaceValue(role.fld_respondent_height),
        fld_respondent_weight:replaceValue(role.fld_respondent_weight),
        fld_circumfernc_waist:replaceValue(role.fld_circumfernc_waist),
        fld_following_chronic_condition_id:replaceValue(role.fld_following_chronic_condition_id),
        fld_following_chronic_condition_name:replaceValue(role.fld_following_chronic_condition_name),
      }));
      setTab2Data(Tab2Slno);
      //Tab3
      setTab3Data(responseData.trn_tbl_gi_house_hold_detail);
      const Tab3Slno = result.responsemessage.trn_tbl_gi_house_hold_detail.map((role, index) => ({ ...role, slNo: index + 1, 
        // fld_village_name:replaceValue(role.fld_village_name),
        fld_respondent_name:replaceValue(role.fld_respondent_name),
        fld_interviewer_name:replaceValue(role.fld_interviewer_name),
        fld_hh_number:replaceValue(role.fld_hh_number),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
        fld_hh_id:replaceValue(role.fld_hh_id),
        fld_hh_head_name:replaceValue(role.fld_hh_head_name),
        fld_address:replaceValue(role.fld_address),
        fld_age_respondnt:replaceValue(role.fld_age_respondnt),
        fld_gender_respndnt_id:replaceValue(role. fld_gender_respndnt_id),
        fld_gender_respndent_name:replaceValue(role.fld_gender_respndent_name),
        fld_educat_respondnt_id:replaceValue(role.fld_educat_respondnt_id),
        fld_educat_respondnt_name:replaceValue(role.fld_educat_respondnt_name),
        fld_maritlstats_respondnt_id:replaceValue(role.fld_maritlstats_respondnt_id),
        fld_maritlstats_respondnt_name:replaceValue(role.fld_maritlstats_respondnt_name),
        fld_family_membrs:replaceValue(role.fld_family_membrs),
        fld_type_family_id:replaceValue(role.fld_type_family_id),
        fld_type_family_name:replaceValue(role.fld_type_family_name),
        fld_religin_id:replaceValue(role.fld_religin_id),
        fld_religin_name:replaceValue(role.fld_religin_name),
        fld_religin_others_specify:replaceValue(role.fld_religin_others_specify),
        fld_cast_id:replaceValue(role.fld_cast_id),
        fld_cast_name:replaceValue(role.fld_cast_name),
      }));
      setTab3Data(Tab3Slno);
      //Tab4
      setTab4Data(responseData.trn_tbl_family_details);
      const Tab4Slno = result.responsemessage.trn_tbl_family_details.map((role, index) => ({ ...role, slNo: index + 1, 
        fld_name:replaceValue(role.fld_name),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
        fld_relation_hh_name:replaceValue(role.fld_relation_hh_name),
        fld_relationship_other:replaceValue(role.fld_relationship_other),
        fld_age:replaceValue(role.fld_age),
        fld_gender_id:replaceValue(role.fld_gender_id),
        fld_gender_name:replaceValue(role.fld_gender_name),
        fld_occupation_id:replaceValue(role.fld_occupation_id),
        fld_occupation_name:replaceValue(role.fld_occupation_name),
        fld_occup_others_specify:replaceValue(role.fld_occup_others_specify),
        fld_monthly_income:replaceValue(role.fld_monthly_income),
        fld_mem_scrend_for_chronic_dis_id:replaceValue(role.fld_mem_scrend_for_chronic_dis_id),
        fld_mem_scrend_for_chronic_dis_name:replaceValue(role.fld_mem_scrend_for_chronic_dis_name),
        fld_any_fam_mem_chronic_dis_id:replaceValue(role.fld_any_fam_mem_chronic_dis_id),
        fld_any_fam_mem_chronic_dis_name:replaceValue(role.fld_any_fam_mem_chronic_dis_name),
        fld_spcfy_typ_chron_dis_id:replaceValue(role.fld_spcfy_typ_chron_dis_id),
        fld_spcfy_typ_chron_dis_name:replaceValue(role.fld_spcfy_typ_chron_dis_name),
        fld_others_specify:replaceValue(role.fld_others_specify),
        fld_mem_above_18_years_id:replaceValue(role.fld_mem_above_18_years_id),
        fld_mem_above_18_years:replaceValue(role.fld_mem_above_18_years),
      }));
      setTab4Data(Tab4Slno);
      //Tab5
      setTab5Data(responseData.trn_tbl_standred_of_living);
      const Tab5Slno = result.responsemessage.trn_tbl_standred_of_living.map((role, index) => ({ ...role, slNo: index + 1, 
        // fld_make_safe_water_drink_name: replaceSymbol(role.fld_make_safe_water_drink_name),
        // fld_toilet_faclty_hh_mem_use_name: replaceSymbol(role.fld_toilet_faclty_hh_mem_use_name),
        // fld_difficlt_in_reaching_phc_name: replaceSymbol(role.fld_difficlt_in_reaching_phc_name),
        // fld_wht_hapng_area_name: replaceSymbol(role.fld_wht_hapng_area_name),
        // fld_wht_ent_pop_name: replaceSymbol(role.fld_wht_ent_pop_name),
        // fld_commnity_bas_org_in_vill_name: replaceSymbol(role.fld_commnity_bas_org_in_vill_name),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
        fld_ownership_house_id:replaceValue(role.fld_ownership_house_id),
        fld_ownership_house_name:replaceValue(role.fld_ownership_house_name),
        fld_typ_housing_structre_id:replaceValue(role.fld_typ_housing_structre_id),
        fld_typ_housing_structre_name:replaceValue(role.fld_typ_housing_structre_name),
        fld_main_sourc_drink_water_id:replaceValue(role.fld_main_sourc_drink_water_id),
        fld_main_sourc_drink_water_name:replaceValue(role.fld_main_sourc_drink_water_name),
        fld_Drink_watr_other_specify:replaceValue(role.fld_Drink_watr_other_specify),
        fld_make_safe_water_drink_id:replaceValue(role.fld_make_safe_water_drink_id),
        fld_make_safe_water_drink_name:replaceValue(role.fld_make_safe_water_drink_name),
        fld_toilet_faclty_hh_mem_use_id:replaceValue(role.fld_toilet_faclty_hh_mem_use_id),
        fld_toilet_faclty_hh_mem_use_name:replaceValue(role.fld_toilet_faclty_hh_mem_use_name),
        fld_tolit_fac_other_specify:replaceValue(role.fld_tolit_fac_other_specify),
        fld_do_all_family_mem_use_toilet_id:replaceValue(role.fld_do_all_family_mem_use_toilet_id),
        fld_do_all_family_mem_use_toilet_name:replaceValue(role.fld_do_all_family_mem_use_toilet_name),
        fld_if_no_why_id:replaceValue(role.fld_if_no_why_id),
        fld_if_no_why_name:replaceValue(role.fld_if_no_why_name),
        fld_no_why_other_specify:replaceValue(role.fld_no_why_other_specify),
        fld_does_electricity_connection_id:replaceValue(role.fld_does_electricity_connection_id),
        fld_does_electricity_connection_name:replaceValue(role.fld_does_electricity_connection_name),
        fld_typ_fuel_for_cooking_id:replaceValue(role.fld_typ_fuel_for_cooking_id),
        fld_typ_fuel_for_cooking_name:replaceValue(role.fld_typ_fuel_for_cooking_name),
        fld_fule_other_specify:replaceValue(role.fld_fule_other_specify),
        fld_do_u_hav_ration_card_id:replaceValue(role.fld_do_u_hav_ration_card_id),
        fld_do_u_hav_ration_card_name:replaceValue(role.fld_do_u_hav_ration_card_name),
        fld_if_yes_verify_ration_card_id:replaceValue(role.fld_if_yes_verify_ration_card_id),
        fld_if_yes_verify_ration_card_name:replaceValue(role.fld_if_yes_verify_ration_card_name),
        fld_u_any_family_mem_hav_health_ins_policy_id:replaceValue(role.fld_u_any_family_mem_hav_health_ins_policy_id),
        fld_u_any_family_mem_hav_health_ins_policy_name:replaceValue(role.fld_u_any_family_mem_hav_health_ins_policy_name),
        fld_if_yes_verify_insu_card_id:replaceValue(role.fld_if_yes_verify_insu_card_id),
        fld_if_yes_verify_insu_card_name:replaceValue(role.fld_if_yes_verify_insu_card_name),
        fld_privat_ins_scheme_name:replaceValue(role.fld_privat_ins_scheme_name),
        fld_privat_in_other_specify:replaceValue(role.fld_privat_in_other_specify),
        fld_typ_salt_prepare_food_id:replaceValue(role.fld_typ_salt_prepare_food_id),
        fld_typ_salt_prepare_food_name:replaceValue(role.fld_typ_salt_prepare_food_name),
        fld_transport_facilty_public_trans_bus_id:replaceValue(role.fld_transport_facilty_public_trans_bus_id),
        fld_transport_facilty_public_trans_bus_name:replaceValue(role.fld_transport_facilty_public_trans_bus_name),
        fld_transport_facilty_rickshaw_id:replaceValue(role.fld_transport_facilty_rickshaw_id),
        fld_transport_facilty_rickshaw_name:replaceValue(role.fld_transport_facilty_rickshaw_name),
        fld_transport_facilty_auto_rickshaw_id:replaceValue(role.fld_transport_facilty_auto_rickshaw_id),
        fld_transport_facilty_auto_rickshaw_name:replaceValue(role.fld_transport_facilty_auto_rickshaw_name),
        fld_transport_facilty_taxi_van_jeep_id:replaceValue(role.fld_transport_facilty_taxi_van_jeep_id),
        fld_transport_facilty_taxi_van_jeep_name:replaceValue(role.fld_transport_facilty_taxi_van_jeep_name),
        fld_transport_facilty_any_other_specfy_id:replaceValue(role.fld_transport_facilty_any_other_specfy_id),
        fld_transport_facilty_any_other_specfy_name:replaceValue(role.fld_transport_facilty_any_other_specfy_name),
        fld_transport_facility_other_specify:replaceValue(role.fld_transport_facility_other_specify),
        fld_does_vill_hav_mobil_net_id:replaceValue(role.fld_does_vill_hav_mobil_net_id),
        fld_does_vill_hav_mobil_net_name:replaceValue(role.fld_does_vill_hav_mobil_net_name),
        fld_commnity_bas_org_in_vill_id:replaceValue(role.fld_commnity_bas_org_in_vill_id),
        fld_commnity_bas_org_in_vill_name:replaceValue(role.fld_commnity_bas_org_in_vill_name),
        fld_any_cbo_other_specify:replaceValue(role.fld_any_cbo_other_specify),
        fld_wht_hapng_area_id:replaceValue(role.fld_wht_hapng_area_id),
        fld_wht_hapng_area_name:replaceValue(role.fld_wht_hapng_area_name),
        fld_wht_hapn_others_specify:replaceValue(role.fld_wht_hapn_others_specify),
        fld_wht_ent_pop_id:replaceValue(role.fld_wht_ent_pop_id),
        fld_wht_ent_pop_name:replaceValue(role.fld_wht_ent_pop_name),
        fld_entermntt_any_other_specify:replaceValue(role.fld_entermntt_any_other_specify),
        fld_regulr_sorc_med_care_id:replaceValue(role.fld_regulr_sorc_med_care_id),
        fld_regulr_sorc_med_care_name:replaceValue(role.fld_regulr_sorc_med_care_name),
        fld_other_response_specify:replaceValue(role.fld_other_response_specify),
        fld_dist_to_phc_id:replaceValue(role.fld_dist_to_phc_id),
        fld_dist_to_phc:replaceValue(role.fld_dist_to_phc),
        fld_phc_distance:replaceValue(role.fld_phc_distance),
        fld_dist_to_shc_frm_village_id:replaceValue(role.fld_dist_to_shc_frm_village_id),
        fld_dist_to_shc_frm_village:replaceValue(role.fld_dist_to_shc_frm_village),
        fld_shc_distance:replaceValue(role.fld_shc_distance),
        fld_visit_phc_id:replaceValue(role.fld_visit_phc_id),
        fld_visit_phc_name:replaceValue(role.fld_visit_phc_name),
        fld_reason_not_visit_phc_id:replaceValue(role.fld_reason_not_visit_phc_id),
        fld_reason_not_visit_phc_name:replaceValue(role.fld_reason_not_visit_phc_name),
        fld_visit_phc_any_other_specify:replaceValue(role.fld_visit_phc_any_other_specify),
        fld_difficlt_in_reaching_phc_id:replaceValue(role.fld_difficlt_in_reaching_phc_id),
        fld_difficlt_in_reaching_phc_name:replaceValue(role.fld_difficlt_in_reaching_phc_name),
        fld_difficult_faced_any_other_specify:replaceValue(role.fld_difficult_faced_any_other_specify),
      }));
      setTab5Data(Tab5Slno);
      //Tab6
      setTab6Data(responseData.trn_tbl_hospitalization);
      const Tab6Slno = result.responsemessage.trn_tbl_hospitalization.map((role, index) => ({ ...role, slNo: index + 1, 
        // fld_arrang_money_name: replaceSymbol(role.fld_arrang_money_name),
        fld_name:replaceValue(role.fld_name),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
        fld_num_hospit_event:replaceValue(role.fld_num_hospit_event),
        fld_recent_hospi_illns_detals:replaceValue(role.fld_recent_hospi_illns_detals),
        fld_recent_hosptl_name_id:replaceValue(role.fld_recent_hosptl_name_id),
        fld_recent_hosptl_name_name:replaceValue(role.fld_recent_hosptl_name_name),
        fld_hospital_any_other_specify:replaceValue(role.fld_hospital_any_other_specify),
        fld_totl_amnt_spnt_hosptlzn:replaceValue(role.fld_totl_amnt_spnt_hosptlzn),
        fld_arrang_money_id:replaceValue(role.fld_arrang_money_id),
        fld_arrang_money_name:replaceValue(role.fld_arrang_money_name),
        fld_arrang_any_other_specify:replaceValue(role.fld_arrang_any_other_specify),
        fld_did_covr_expnd_helth_schm_id:replaceValue(role.fld_did_covr_expnd_helth_schm_id),
        fld_did_covr_expnd_helth_schm_name:replaceValue(role.fld_did_covr_expnd_helth_schm_name),
        fld_name_schme:replaceValue(role.fld_name_schme),
      }));
      setTab6Data(Tab6Slno);
      //Tab7
      setTab7Data(responseData.trn_tbl_chornic_illnesses);
      const Tab7Slno = result.responsemessage.trn_tbl_chornic_illnesses.map((role, index) => ({ ...role, slNo: index + 1,
        // fld_arrang_money_name: replaceSymbol(role.fld_arrang_money_name),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
        fld_name:replaceValue(role.fld_name),
        fld_details_chron_ill_id:replaceValue(role.fld_details_chron_ill_id),
        fld_details_chron_ill_name:replaceValue(role.fld_details_chron_ill_name),
        fld_Cronic_ill_other_specify:replaceValue(role.fld_Cronic_ill_other_specify),
        fld_since_how_mny_years:replaceValue(role.fld_since_how_mny_years),
        fld_source_trtment_id:replaceValue(role.fld_source_trtment_id),
        fld_source_trtment_name:replaceValue(role.fld_source_trtment_name),
        fld_sourc_tret_any_other_specify:replaceValue(role.fld_sourc_tret_any_other_specify),
        fld_mny_spnt_for_illnss:replaceValue(role.fld_mny_spnt_for_illnss),
        fld_major_expnd_fr_illnss:replaceValue(role.fld_major_expnd_fr_illnss),
        fld_arrang_money_id:replaceValue(role.fld_arrang_money_id),
        fld_arrang_money_name:replaceValue(role.fld_arrang_money_name),
        fld_arrang_mony_any_other_specify:replaceValue(role.fld_arrang_mony_any_other_specify),
        fld_expnd_covr_helth_schem_id:replaceValue(role.fld_expnd_covr_helth_schem_id),
        fld_expnd_covr_helth_schem_name:replaceValue(role.fld_expnd_covr_helth_schem_name),
        fld_name_schme:replaceValue(role.fld_name_schme),
      }));
      setTab7Data(Tab7Slno);
      //Tab8
      setTab8Data(responseData.trn_tbl_hypertension);
      const Tab8Slno = result.responsemessage.trn_tbl_hypertension.map((role, index) => ({ ...role, slNo: index + 1, 
        // fld_reson_not_visit_doc_name: replaceSymbol(role.fld_reson_not_visit_doc_name),
        // fld_typ_medcn_taking_name: replaceSymbol(role.fld_typ_medcn_taking_name),
        // fld_resn_for_not_takng_medicn_name: replaceSymbol(role.fld_resn_for_not_takng_medicn_name),
        // fld_oth_thng_doing_cont_bp_name: replaceSymbol(role.fld_oth_thng_doing_cont_bp_name),
        // fld_sign_symp_high_bp_name: replaceSymbol(role.fld_sign_symp_high_bp_name),
        // fld_happn_bp_remns_untreatd_name: replaceSymbol(role.fld_happn_bp_remns_untreatd_name),
        // fld_how_prevnt_hyprtnsn_name: replaceSymbol(role.fld_how_prevnt_hyprtnsn_name),
        // fld_sourc_awarnss_hypertnsn_name: replaceSymbol(role.fld_sourc_awarnss_hypertnsn_name),
        fld_date_of_interview:formatDate(role.fld_date_of_interview),
        fld_name:replaceValue(role.fld_name),
        fld_bp_duaration_id:replaceValue(role.fld_bp_duaration_id),
        fld_bp_duaration_name:replaceValue(role.fld_bp_duaration_name),
        fld_num_of_years:replaceValue(role.fld_num_of_years),
        fld_lst_scrnd_hypertnsn_id:replaceValue(role.fld_lst_scrnd_hypertnsn_id),
        fld_lst_scrnd_hypertnsn_name:replaceValue(role.fld_lst_scrnd_hypertnsn_name),
        fld_lst_time_bp_what_id:replaceValue(role.fld_lst_time_bp_what_id),
        fld_lst_time_bp_what_name:replaceValue(role.fld_lst_time_bp_what_name),
        fld_bp_value:replaceValue(role.fld_bp_value),
        fld_screning_plac_lst_tim_id:replaceValue(role.fld_screning_plac_lst_tim_id),
        fld_screning_plac_lst_tim_name:replaceValue(role.fld_screning_plac_lst_tim_name),
        fld_screen_place_other_specify:replaceValue(role.fld_screen_place_other_specify),
        fld_doc_vist_bp_scrning_id:replaceValue(role.fld_doc_vist_bp_scrning_id),
        fld_doc_vist_bp_scrning_name:replaceValue(role.fld_doc_vist_bp_scrning_name),
        fld_reson_not_visit_doc_id:replaceValue(role.fld_reson_not_visit_doc_id),
        fld_reson_not_visit_doc_name:replaceValue(role.fld_reson_not_visit_doc_name),
        fld_reason_visit_other_specify:replaceValue(role.fld_reason_visit_other_specify),
        fld_refer_to_faclty_bp_issue_id:replaceValue(role.fld_refer_to_faclty_bp_issue_id),
        fld_refer_to_faclty_bp_issue_name:replaceValue(role.fld_refer_to_faclty_bp_issue_name),
        fld_if_yes_where_id:replaceValue(role.fld_if_yes_where_id),
        fld_if_yes_where:replaceValue(role.fld_if_yes_where),
        fld_others_specify:replaceValue(role.fld_others_specify),
        fld_visit_refrd_faclty_id:replaceValue(role.fld_visit_refrd_faclty_id),
        fld_visit_refrd_faclty_name:replaceValue(role.fld_visit_refrd_faclty_name),
        fld_if_no_why:replaceValue(role.fld_if_no_why),
        fld_visit_other_faclty_id:replaceValue(role.fld_visit_other_faclty_id),
        fld_visit_other_faclty_name:replaceValue(role.fld_visit_other_faclty_name),
        fld_specify_faclty_name:replaceValue(role.fld_specify_faclty_name),
        fld_follw_hyprtnsn_mgm_id:replaceValue(role.fld_follw_hyprtnsn_mgm_id),
        fld_follw_hyprtnsn_mgm_name:replaceValue(role.fld_follw_hyprtnsn_mgm_name),
        fld_hyprtnsn_other_specify:replaceValue(role.fld_hyprtnsn_other_specify),
        fld_reson_for_prefernc:replaceValue(role.fld_reson_for_prefernc),
        fld_tak_medcn_for_hyprtnsn_id:replaceValue(role.fld_tak_medcn_for_hyprtnsn_id),
        fld_tak_medcn_for_hyprtnsn_name:replaceValue(role.fld_tak_medcn_for_hyprtnsn_name),
        fld_resn_for_not_takng_medcn_id:replaceValue(role.fld_resn_for_not_takng_medcn_id),
        fld_resn_for_not_takng_medcn_name:replaceValue(role.fld_resn_for_not_takng_medcn_name),
        fld_med_not_tkn_reson_other_spicfy:replaceValue(role.fld_med_not_tkn_reson_other_spicfy),
        fld_typ_medcn_taking_id:replaceValue(role.fld_typ_medcn_taking_id),
        fld_typ_medcn_taking_name:replaceValue(role.fld_typ_medcn_taking_name),
        fld_typ_med_other_spicfy:replaceValue(role.fld_typ_med_other_spicfy),
        fld_what_medcn_taking:replaceValue(role.fld_what_medcn_taking),
        fld_what_dosage:replaceValue(role.fld_what_dosage),
        fld_freq_takng_medcn_id:replaceValue(role.fld_freq_takng_medcn_id),
        fld_freq_takng_medcn_name:replaceValue(role.fld_freq_takng_medcn_name),
        fld_resn_for_not_takng_medicn_id:replaceValue(role.fld_resn_for_not_takng_medicn_id),
        fld_resn_for_not_takng_medicn_name:replaceValue(role.fld_resn_for_not_takng_medicn_name),
        fld_med_daly_other_specify:replaceValue(role.fld_med_daly_other_specify),
        fld_oth_thng_doing_cont_bp_id:replaceValue(role.fld_oth_thng_doing_cont_bp_id),
        fld_oth_thng_doing_cont_bp_name:replaceValue(role.fld_oth_thng_doing_cont_bp_name),
        fld_control_bp_other_specify:replaceValue(role.fld_control_bp_other_specify),
        fld_norm_bp_range_id:replaceValue(role.fld_norm_bp_range_id),
        fld_norm_bp_range_name:replaceValue(role.fld_norm_bp_range_name),
        fld_sign_symp_high_bp_id:replaceValue(role.fld_sign_symp_high_bp_id),
        fld_sign_symp_high_bp_name:replaceValue(role.fld_sign_symp_high_bp_name),
        fld_signsypt_other_specify:replaceValue(role.fld_signsypt_other_specify),
        fld_causes_high_bp_id:replaceValue(role.fld_causes_high_bp_id),
        fld_causes_high_bp_name:replaceValue(role.fld_causes_high_bp_name),
        fld_hbp_other_specify:replaceValue(role.fld_hbp_other_specify),
        fld_happn_bp_remns_untreatd_id:replaceValue(role.fld_happn_bp_remns_untreatd_id),
        fld_happn_bp_remns_untreatd_name:replaceValue(role.fld_happn_bp_remns_untreatd_name),
        fld_bp_other_specify:replaceValue(role.fld_bp_other_specify),
        fld_how_prevnt_hyprtnsn_id:replaceValue(role.fld_how_prevnt_hyprtnsn_id),
        fld_how_prevnt_hyprtnsn_name:replaceValue(role.fld_how_prevnt_hyprtnsn_name),
        fld_hypertnsn_other_specify:replaceValue(role.fld_hypertnsn_other_specify),
        fld_how_mng_hypertsiv_name:replaceValue(role.fld_how_mng_hypertsiv_name),
        fld_how_mng_hypertsiv_id:replaceValue(role.fld_how_mng_hypertsiv_id),
        fld_how_mng_hypertsiv_other:replaceValue(role.fld_how_mng_hypertsiv_other),
        fld_sourc_awarnss_hypertnsn_id:replaceValue(role.fld_sourc_awarnss_hypertnsn_id),
        fld_sourc_awarnss_hypertnsn_name:replaceValue(role.fld_sourc_awarnss_hypertnsn_name),
        fld_awarn_ht_other_specify:replaceValue(role.fld_awarn_ht_other_specify),
      }));
      setTab8Data(Tab8Slno);
      //Tab9
      setTab9Data(responseData.trn_tbl_diabets);
        const Tab9Slno = result.responsemessage.trn_tbl_diabets.map((role, index) => ({ ...role, slNo: index + 1,
          // fld_how_manage_dibtes_name: replaceSymbol(role.fld_how_manage_dibtes_name),
          // fld_complicasn_uncont_dibtes_name: replaceSymbol(role.fld_complicasn_uncont_dibtes_name),
          // fld_oth_thng_doing_cont_dibtes_name: replaceSymbol(role.fld_oth_thng_doing_cont_dibtes_name),
          // fld_sign_symp_dibtes_name: replaceSymbol(role.fld_sign_symp_dibtes_name),
          // fld_causes_dibtes_name: replaceSymbol(role.fld_causes_dibtes_name),
          // fld_how_prevnt_dibtes_name: replaceSymbol(role.fld_how_prevnt_dibtes_name),
          // fld_sourc_awarnss_dibtes_name: replaceSymbol(role.fld_sourc_awarnss_dibtes_name),
          fld_date_of_interview:formatDate(role.fld_date_of_interview),
          fld_name:replaceValue(role.fld_name),
          fld_since_how_long_diabtes_id:replaceValue(role.fld_since_how_long_diabtes_id),
          fld_since_how_long_diabtes_name:replaceValue(role.fld_since_how_long_diabtes_name),
          fld_num_of_years:replaceValue(role.fld_num_of_years),
          fld_whn_lst_scrnd_for_diabtes_id:replaceValue(role.fld_whn_lst_scrnd_for_diabtes_id),
          fld_whn_lst_scrnd_for_diabtes_name:replaceValue(role.fld_whn_lst_scrnd_for_diabtes_name),
          fld_rang_diabtes_chekd_lst_id:replaceValue(role.fld_rang_diabtes_chekd_lst_id),
          fld_rang_diabtes_chekd_lst_name:replaceValue(role.fld_rang_diabtes_chekd_lst_name),
          fld_place_scrning_lst_id:replaceValue(role.fld_place_scrning_lst_id),
          fld_place_scrning_lst_name:replaceValue(role.fld_place_scrning_lst_name),
          fld_screening_other_specify:replaceValue(role.fld_screening_other_specify),
          fld_doc_vist_dibtes_scrning_id:replaceValue(role.fld_doc_vist_dibtes_scrning_id),
          fld_doc_vist_dibtes_scrning_name:replaceValue(role.fld_doc_vist_dibtes_scrning_name),
          fld_reson_not_visit_doc_id:replaceValue(role.fld_reson_not_visit_doc_id),
          fld_reson_not_visit_doc_name:replaceValue(role.fld_reson_not_visit_doc_name),
          fld_not_visit_doc_other_specify:replaceValue(role.fld_not_visit_doc_other_specify),
          fld_refer_to_faclty_dibtes_issue_id:replaceValue(role.fld_refer_to_faclty_dibtes_issue_id),
          fld_refer_to_faclty_dibtes_issue_name:replaceValue(role.fld_refer_to_faclty_dibtes_issue_name),
          fld_if_yes_where_id:replaceValue(role.fld_if_yes_where_id),
          fld_if_yes_where:replaceValue(role.fld_if_yes_where),
          fld_others_specify:replaceValue(role.fld_others_specify),
          fld_visit_refrd_faclty_id:replaceValue(role.fld_visit_refrd_faclty_id),
          fld_visit_refrd_faclty_name:replaceValue(role.fld_visit_refrd_faclty_name),
          fld_if_no_why:replaceValue(role.fld_if_no_why),
          fld_visit_other_faclty_id:replaceValue(role.fld_visit_other_faclty_id),
          fld_visit_other_faclty_name:replaceValue(role.fld_visit_other_faclty_name),
          fld_specify_faclty_id:replaceValue(role.fld_specify_faclty_id),
          fld_specify_faclty_name:replaceValue(role.fld_specify_faclty_name),
          fld_follw_dibtes_mgm_id:replaceValue(role.fld_follw_dibtes_mgm_id),
          fld_follw_dibtes_mgm_name:replaceValue(role.fld_follw_dibtes_mgm_name),
          fld_dibts_other_specify:replaceValue(role.fld_dibts_other_specify),
          fld_reson_for_prefernc:replaceValue(role.fld_reson_for_prefernc),
          fld_tak_medcn_for_dibates_id:replaceValue(role.fld_tak_medcn_for_dibates_id),
          fld_tak_medcn_for_dibates_name:replaceValue(role.fld_tak_medcn_for_dibates_name),
          fld_resn_for_not_takng_medcn_id:replaceValue(role.fld_resn_for_not_takng_medcn_id),
          fld_resn_for_not_takng_medcn_name:replaceValue(role.fld_resn_for_not_takng_medcn_name),
          fld_med_not_tkn_other_spicfy:replaceValue(role.fld_med_not_tkn_other_spicfy),
          fld_typ_medcn_taking_id:replaceValue(role.fld_typ_medcn_taking_id),
          fld_typ_medcn_taking_name:replaceValue(role.fld_typ_medcn_taking_name),
          fld_med_typ_other_spicfy:replaceValue(role.fld_med_typ_other_spicfy),
          fld_what_medcn_taking:replaceValue(role.fld_what_medcn_taking),
          fld_what_dosage:replaceValue(role.fld_what_dosage),
          fld_freq_takng_medcn_id:replaceValue(role.fld_freq_takng_medcn_id),
          fld_freq_takng_medcn_name:replaceValue(role.fld_freq_takng_medcn_name),
          fld_freq_med_tak_other_spicfy:replaceValue(role.fld_freq_med_tak_other_spicfy),
          fld_resn_for_not_consum_medicn_id:replaceValue(role.fld_resn_for_not_consum_medicn_id),
          fld_resn_for_not_consum_medicn_name:replaceValue(role.fld_resn_for_not_consum_medicn_name),
          fld_reson_med_other_specify:replaceValue(role.fld_reson_med_other_specify),
          fld_oth_thng_doing_cont_dibtes_id:replaceValue(role.fld_oth_thng_doing_cont_dibtes_id),
          fld_oth_thng_doing_cont_dibtes_name:replaceValue(role.fld_oth_thng_doing_cont_dibtes_name),
          fld_cont_diabts_other_specify:replaceValue(role.fld_cont_diabts_other_specify),
          fld_norm_fast_blod_glucos_level_id:replaceValue(role.fld_norm_fast_blod_glucos_level_id),
          fld_norm_fast_blod_glucos_level_name:replaceValue(role.fld_norm_fast_blod_glucos_level_name),
          fld_sign_symp_dibtes_id:replaceValue(role.fld_sign_symp_dibtes_id),
          fld_sign_symp_dibtes_name:replaceValue(role.fld_sign_symp_dibtes_name),
          fld_sypt_diabt_other_specify:replaceValue(role.fld_sypt_diabt_other_specify),
          fld_causes_dibtes_id:replaceValue(role.fld_causes_dibtes_id),
          fld_causes_dibtes_name:replaceValue(role.fld_causes_dibtes_name),
          fld_caus_diabt_other_specify:replaceValue(role.fld_caus_diabt_other_specify),
          fld_complicasn_uncont_dibtes_id:replaceValue(role.fld_complicasn_uncont_dibtes_id),
          fld_complicasn_uncont_dibtes_name:replaceValue(role.fld_complicasn_uncont_dibtes_name),
          fld_unctrl_diabtes_other_specify:replaceValue(role.fld_unctrl_diabtes_other_specify),
          fld_how_prevnt_dibtes_id:replaceValue(role.fld_how_prevnt_dibtes_id),
          fld_how_prevnt_dibtes_name:replaceValue(role.fld_how_prevnt_dibtes_name),
          fld_prev_diabts_other_specify:replaceValue(role.fld_prev_diabts_other_specify),
          fld_how_manage_dibtes_id:replaceValue(role.fld_how_manage_dibtes_id),
          fld_how_manage_dibtes_name:replaceValue(role.fld_how_manage_dibtes_name),
          fld_manag_dibts_other_specify:replaceValue(role.fld_manag_dibts_other_specify),
          fld_sourc_awarnss_dibtes_id:replaceValue(role.fld_sourc_awarnss_dibtes_id),
          fld_sourc_awarnss_dibtes_name:replaceValue(role.fld_sourc_awarnss_dibtes_name),
          fld_awrns_ht_other_specify:replaceValue(role.fld_awrns_ht_other_specify),
      }));
      setTab9Data(Tab9Slno);
      //Tab10
      setTab10Data(responseData.trn_tbl_diet_and_nutrition);
         const Tab10Slno = result.responsemessage.trn_tbl_diet_and_nutrition.map((role, index) => ({ ...role, slNo: index + 1,
          fld_name:replaceValue(role.fld_name),
          fld_date_of_interview:formatDate(role.fld_date_of_interview),
          fld_type_diet_follw_id:replaceValue(role.fld_type_diet_follw_id),
          fld_type_diet_follw_name:replaceValue(role.fld_type_diet_follw_name),
          fld_do_u_eat_folw_meal_id:replaceValue(role.fld_do_u_eat_folw_meal_id),
          fld_do_u_eat_folw_meal_name:replaceValue(role.fld_do_u_eat_folw_meal_name),
          fld_which_meal_miss_id:replaceValue(role.fld_which_meal_miss_id),
          fld_which_meal_miss_name:replaceValue(role.fld_which_meal_miss_name),
          fld_why_miss_meal_id:replaceValue(role.fld_why_miss_meal_id),
          fld_why_miss_meal_meal:replaceValue(role.fld_why_miss_meal_meal),
          fld_miss_meal_other_reasons:replaceValue(role.fld_miss_meal_other_reasons),
          fld_kind_of_diet_restrct_follw_id:replaceValue(role.fld_kind_of_diet_restrct_follw_id),
          fld_kind_of_diet_restrct_follw_name:replaceValue(role.fld_kind_of_diet_restrct_follw_name),
          fld_diet_restrct_others_specify:replaceValue(role.fld_diet_restrct_others_specify),
          fld_food_consupsn_cereals_id:replaceValue(role.fld_food_consupsn_cereals_id),
          fld_food_consupsn_cereals_name:replaceValue(role.fld_food_consupsn_cereals_name),
          fld_food_consupsn_pulses_id:replaceValue(role.fld_food_consupsn_pulses_id),
          fld_food_consupsn_pulses_name:replaceValue(role.fld_food_consupsn_pulses_name),
          fld_food_consupsn_gren_leaf_vegt_id:replaceValue(role.fld_food_consupsn_gren_leaf_vegt_id),
          fld_food_consupsn_gren_leaf_vegt_name:replaceValue(role.fld_food_consupsn_gren_leaf_vegt_name),
          fld_food_consupsn_other_vegt_id:replaceValue(role.fld_food_consupsn_other_vegt_id),
          fld_food_consupsn_other_vegt_name:replaceValue(role.fld_food_consupsn_other_vegt_name),
          fld_food_consupsn_milk_prodct_id:replaceValue(role.fld_food_consupsn_milk_prodct_id),
          fld_food_consupsn_milk_prodct_name:replaceValue(role.fld_food_consupsn_milk_prodct_name),
          fld_food_consupsn_fruits_id:replaceValue(role.fld_food_consupsn_fruits_id),
          fld_food_consupsn_fruits_name:replaceValue(role.fld_food_consupsn_fruits_name),
          fld_food_consupsn_egg_id:replaceValue(role.fld_food_consupsn_egg_id),
          fld_food_consupsn_egg_name:replaceValue(role.fld_food_consupsn_egg_name),
          fld_food_consupsn_meat_chikn_id:replaceValue(role.fld_food_consupsn_meat_chikn_id),
          fld_food_consupsn_meat_chikn_name:replaceValue(role.fld_food_consupsn_meat_chikn_name),
          fld_food_consupsn_fast_food_id:replaceValue(role.fld_food_consupsn_fast_food_id),
          fld_food_consupsn_fast_food_name:replaceValue(role.fld_food_consupsn_fast_food_name),
          fld_food_consupsn_soft_drink_id:replaceValue(role.fld_food_consupsn_soft_drink_id),
          fld_food_consupsn_soft_drink_name:replaceValue(role.fld_food_consupsn_soft_drink_name),
         }));
      setTab10Data(Tab10Slno);
      //Tab11
      setTab11Data(responseData.trn_tbl_health_problem);
        const Tab11Slno = result.responsemessage.trn_tbl_health_problem.map((role, index) => ({ ...role, slNo: index + 1, 
          fld_name:replaceNegativeWithValue(role.fld_name),
          fld_date_of_interview:formatDate(role.fld_date_of_interview),
          fld_oth_hlth_prob_nothng_id:replaceNegativeWithValue(role.fld_oth_hlth_prob_nothng_id),
          fld_oth_hlth_prob_nothng_name:replaceNegativeWithValue(role.fld_oth_hlth_prob_nothng_name),
          fld_heart_disease_id:replaceNegativeWithValue(role.fld_heart_disease_id),
          fld_heart_disease_name:replaceNegativeWithValue(role.fld_heart_disease_name),
          fld_yes_taking_hd_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_hd_trtmnt_id),
          fld_yes_taking_hd_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_hd_trtmnt_name),
          fld_place_of_trtmnt_hd_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_hd_id),
          fld_place_of_trtmnt_hd_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_hd_name),
          fld_hd_pt_hd_other_specify:replaceNegativeWithValue(role.fld_hd_pt_hd_other_specify),
          fld_stroke_id:replaceNegativeWithValue(role.fld_stroke_id),
          fld_stroke_name:replaceNegativeWithValue(role.fld_stroke_name),
          fld_yes_taking_stok_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_stok_trtmnt_id),
          fld_yes_taking_stok_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_stok_trtmnt_name),
          fld_place_of_trtmnt_stok_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_stok_id),
          fld_place_of_trtmnt_stok_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_stok_name),
          fld_strok_pt_stok_other_specify:replaceNegativeWithValue(role.fld_strok_pt_stok_other_specify),
          fld_asthma_id:replaceNegativeWithValue(role.fld_asthma_id),
          fld_asthma_name:replaceNegativeWithValue(role.fld_asthma_name),
          fld_yes_taking_astma_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_astma_trtmnt_id),
          fld_yes_taking_astma_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_astma_trtmnt_name),
          fld_place_of_trtmnt_astma_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_astma_id),
          fld_place_of_trtmnt_astma_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_astma_name),
          fld_asthma_pt_astma_other_specify:replaceNegativeWithValue(role.fld_asthma_pt_astma_other_specify),
          fld_pcos_id:replaceNegativeWithValue(role.fld_pcos_id),
          fld_pcos_name:replaceNegativeWithValue(role.fld_pcos_name),
          fld_yes_taking_pcos_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_pcos_trtmnt_id),
          fld_yes_taking_pcos_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_pcos_trtmnt_name),
          fld_place_of_trtmnt_pcos_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_pcos_id),
          fld_place_of_trtmnt_pcos_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_pcos_name),
          fld_pcos_pt_pcos_other_specify:replaceNegativeWithValue(role.fld_pcos_pt_pcos_other_specify),
          fld_thyroid_id:replaceNegativeWithValue(role.fld_thyroid_id),
          fld_thyroid_name:replaceNegativeWithValue(role.fld_thyroid_name),
          fld_yes_taking_thy_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_thy_trtmnt_id),
          fld_yes_taking_thy_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_thy_trtmnt_name),
          fld_place_of_trtmnt_thy_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_thy_id),
          fld_place_of_trtmnt_thy_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_thy_name),
          fld_thyroid_pt_thy_other_specify:replaceNegativeWithValue(role.fld_thyroid_pt_thy_other_specify),
          fld_teeth_or_gum_id:replaceNegativeWithValue(role.fld_teeth_or_gum_id),
          fld_teeth_or_gum_name:replaceNegativeWithValue(role.fld_teeth_or_gum_name),
          fld_yes_taking_tgum_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_tgum_trtmnt_id),
          fld_yes_taking_tgum_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_tgum_trtmnt_name),
          fld_place_of_trtmnt_tgum_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_tgum_id),
          fld_place_of_trtmnt_tgum_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_tgum_name),
          fld_teethgum_pt_tgum_other_specify:replaceNegativeWithValue(role.fld_teethgum_pt_tgum_other_specify),
          fld_retinopathy_id:replaceNegativeWithValue(role.fld_retinopathy_id),
          fld_retinopathy_name:replaceNegativeWithValue(role.fld_retinopathy_name),
          fld_yes_taking_retpty_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_retpty_trtmnt_id),
          fld_yes_taking_retpty_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_retpty_trtmnt_name),
          fld_place_of_trtmnt_retpty_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_retpty_id),
          fld_place_of_trtmnt_retpty_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_retpty_name),
          fld_retino_pt_retpty_other_specify:replaceNegativeWithValue(role.fld_retino_pt_retpty_other_specify),
          fld_sexul_dysfunction_id:replaceNegativeWithValue(role.fld_sexul_dysfunction_id),
          fld_sexul_dysfunction_name:replaceNegativeWithValue(role.fld_sexul_dysfunction_name),
          fld_yes_taking_sexdys_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_sexdys_trtmnt_id),
          fld_yes_taking_sexdys_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_sexdys_trtmnt_name),
          fld_place_of_trtmnt_sexdys_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_sexdys_id),
          fld_place_of_trtmnt_sexdys_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_sexdys_name),
          fld_sd_pt_sexdys_other_specify:replaceNegativeWithValue(role.fld_sd_pt_sexdys_other_specify),
          fld_other_health_prob_id:replaceNegativeWithValue(role.fld_other_health_prob_id),
          fld_other_health_prob_name:replaceNegativeWithValue(role.fld_other_health_prob_name),
          fld_yes_taking_othersp_trtmnt_id:replaceNegativeWithValue(role.fld_yes_taking_othersp_trtmnt_id),
          fld_yes_taking_othersp_trtmnt_name:replaceNegativeWithValue(role.fld_yes_taking_othersp_trtmnt_name),
          fld_place_of_trtmnt_othersp_id:replaceNegativeWithValue(role.fld_place_of_trtmnt_othersp_id),
          fld_place_of_trtmnt_othersp_name:replaceNegativeWithValue(role.fld_place_of_trtmnt_othersp_name),
          fld_other_pt_othersp_other_specify:replaceNegativeWithValue(role.fld_other_pt_othersp_other_specify),
          fld_any_mod_phys_actvt_id:replaceNegativeWithValue(role.fld_any_mod_phys_actvt_id),
          fld_any_mod_phys_actvt_name:replaceNegativeWithValue(role.fld_any_mod_phys_actvt_name),
          fld_mod_phys_actvt_fqcy_hrs_id:replaceNegativeWithValue(role.fld_mod_phys_actvt_fqcy_hrs_id),
          fld_mod_phys_actvt_fqcy_hrs_name:replaceNegativeWithValue(role.fld_mod_phys_actvt_fqcy_hrs_name),
          fld_total_mod_hrs_spnd_week:replaceNegativeWithValue(role.fld_total_mod_hrs_spnd_week),
          fld_any_vigo_phys_actvt_id:replaceNegativeWithValue(role.fld_any_vigo_phys_actvt_id),
          fld_any_vigo_phys_actvt_name:replaceNegativeWithValue(role.fld_any_vigo_phys_actvt_name),
          fld_vigo_phys_actvt_fqcy_hrs_id:replaceNegativeWithValue(role.fld_vigo_phys_actvt_fqcy_hrs_id),
          fld_vigo_phys_actvt_fqcy_hrs_name:replaceNegativeWithValue(role.fld_vigo_phys_actvt_fqcy_hrs_name),
          fld_total_vigo_hrs_spnd_week:replaceNegativeWithValue(role.fld_total_vigo_hrs_spnd_week),
          fld_attnd_yoga_sesion_id:replaceNegativeWithValue(role.fld_attnd_yoga_sesion_id),
          fld_attnd_yoga_sesion_name:replaceNegativeWithValue(role.fld_attnd_yoga_sesion_name),
          fld_atnd_where:replaceNegativeWithValue(role.fld_atnd_where),
          fld_at_present_do_you_have_the_following_habits_id:replaceNegativeWithValue(role.fld_at_present_do_you_have_the_following_habits_id),
          fld_at_present_do_you_have_the_following_habits_name:replaceNegativeWithValue(role.fld_at_present_do_you_have_the_following_habits_name),
          fld_habit_drink_alcho_id:replaceNegativeWithValue(role.fld_habit_drink_alcho_id),
          fld_habit_drink_alcho_name:replaceNegativeWithValue(role.fld_habit_drink_alcho_name),
          fld_drink_alcho_fqcy_hrs_id:replaceNegativeWithValue(role.fld_drink_alcho_fqcy_hrs_id),
          fld_drink_alcho_fqcy_hrs_name:replaceNegativeWithValue(role.fld_drink_alcho_fqcy_hrs_name),
          fld_habit_smok_tobco_id:replaceNegativeWithValue(role.fld_habit_smok_tobco_id),
          fld_habit_smok_tobco_name:replaceNegativeWithValue(role.fld_habit_smok_tobco_name),
          fld_smok_tobco_fqcy_hrs_id:replaceNegativeWithValue(role.fld_smok_tobco_fqcy_hrs_id),
          fld_smok_tobco_fqcy_hrs_name:replaceNegativeWithValue(role.fld_smok_tobco_fqcy_hrs_name),
          fld_habit_smls_tobco_id:replaceNegativeWithValue(role.fld_habit_smls_tobco_id),
          fld_habit_smls_tobco_name:replaceNegativeWithValue(role.fld_habit_smls_tobco_name),
          fld_smls_tobco_fqcy_hrs_id:replaceNegativeWithValue(role.fld_smls_tobco_fqcy_hrs_id),
          fld_smls_tobco_fqcy_hrs_name:replaceNegativeWithValue(role.fld_smls_tobco_fqcy_hrs_name),
          fld_habit_of_consuming_the_following_in_the_past_id:replaceNegativeWithValue(role.fld_habit_of_consuming_the_following_in_the_past_id),
          fld_habit_of_consuming_the_following_in_the_past_name:replaceNegativeWithValue(role.fld_habit_of_consuming_the_following_in_the_past_name),
          fld_pst_habit_drink_alcho_id:replaceNegativeWithValue(role.fld_pst_habit_drink_alcho_id),
          fld_pst_habit_drink_alcho_name:replaceNegativeWithValue(role.fld_pst_habit_drink_alcho_name),
          fld_pst_drink_alcho_fqcy_hrs_id:replaceNegativeWithValue(role.fld_pst_drink_alcho_fqcy_hrs_id),
          fld_pst_drink_alcho_fqcy_hrs_name:replaceNegativeWithValue(role.fld_pst_drink_alcho_fqcy_hrs_name),
          fld_pst_habit_smok_tobco_id:replaceNegativeWithValue(role.fld_pst_habit_smok_tobco_id),
          fld_pst_habit_smok_tobco_name:replaceNegativeWithValue(role.fld_pst_habit_smok_tobco_name),
          fld_pst_smok_tobco_fqcy_hrs_id:replaceNegativeWithValue(role.fld_pst_smok_tobco_fqcy_hrs_id),
          fld_pst_smok_tobco_fqcy_hrs_name:replaceNegativeWithValue(role.fld_pst_smok_tobco_fqcy_hrs_name),
          fld_pst_habit_smls_tobco_id:replaceNegativeWithValue(role.fld_pst_habit_smls_tobco_id),
          fld_pst_habit_smls_tobco_name:replaceNegativeWithValue(role.fld_pst_habit_smls_tobco_name),
          fld_pst_smls_tobco_fqcy_hrs_id:replaceNegativeWithValue(role.fld_pst_smls_tobco_fqcy_hrs_id),
          fld_pst_smls_tobco_fqcy_hrs_name:replaceNegativeWithValue(role.fld_pst_smls_tobco_fqcy_hrs_name),
          fld_will_partcpet_prgm_id:replaceNegativeWithValue(role.fld_will_partcpet_prgm_id),
          fld_will_partcpet_prgm_name:replaceNegativeWithValue(role.fld_will_partcpet_prgm_name),
          fld_own_mobile_id:replaceNegativeWithValue(role.fld_own_mobile_id),
          fld_own_mobile_name:replaceNegativeWithValue(role.fld_own_mobile_name),
          fld_spicfy_mob_num:replaceNegativeWithValue(role.fld_spicfy_mob_num),
          fld_type_mob_own_id:replaceNegativeWithValue(role.fld_type_mob_own_id),
          fld_type_mob_own_name:replaceNegativeWithValue(role.fld_type_mob_own_name),
          fld_fm_own_mob_id:replaceNegativeWithValue(role.fld_fm_own_mob_id),
          fld_fm_own_mob_name:replaceNegativeWithValue(role.fld_fm_own_mob_name),
          fld_specfy_mob_num_rcv_call:replaceNegativeWithValue(role.fld_specfy_mob_num_rcv_call),
          fld_fam_mob_typ_id:replaceNegativeWithValue(role.fld_fam_mob_typ_id),
          fld_fam_mob_typ_name:replaceNegativeWithValue(role.fld_fam_mob_typ_name),
          fld_pref_time_call_msg:replaceNegativeWithValue(role.fld_pref_time_call_msg),
      }));
      setTab11Data(Tab11Slno);
    }
    else{
      console.log("api res", result.responsemessage);
      const errorMessage = result
        ? result.responsemessage || "No data found"
        : "Unexpected API response format";
      toast.current.show({
        severity: "error",
        summary: "Download Failed",
        detail: errorMessage,
      });
    }
    } catch (error) {
      console.error("Error fetching requested data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    rawData();
  }, []);
  const excelHeaders={
    tab1Data:[ 
      { key : "slNo", text : "SlNo" },      
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_loggedin_user_id", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },      
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_user_name", text : "User name" },
      { key : "fld_respondent_name", text : "Name of the Respondent" }, 
      { key : "fld_agreed_paricipate_survy_id", text : "Agree to participate ID" },
      { key : "fld_agreed_paricipate_survy_name", text : "Do you agree to participate in this survey?" },
    ],
    tab2Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" },
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      // { key : "fld_respondent_id", text : "ID of the Respondent" }, 
      { key : "fld_respondent_name", text : "Name of the Respondent" }, 
      { key : "fld_hh_number", text : "Household Number" }, 
      { key : "fld_respondent_height", text : "Height of the Respondent (In cm)" },
      { key : "fld_respondent_weight", text : "Weight of the respondent (In kg)" },
      { key : "fld_circumfernc_waist", text : "Waist circumference (In cm)" },
      { key : "fld_following_chronic_condition_id", text : "Chronic condition ID" },
      { key : "fld_following_chronic_condition_name", text : "Do you have the following chronic condition" },
    ],

    tab3Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_respondent_name", text : "Name of the Respondent" }, 
      { key : "fld_hh_number", text : "Household Number" }, 
      { key : "fld_hh_head_name", text : "Name Of head of Household" },
      { key : "fld_address", text : "Address" }, 
      { key : "fld_age_respondnt", text : "Age of the Respondent" }, 
      { key : "fld_gender_respndnt_id", text : "Gender ID" }, 
      { key : "fld_gender_respndent_name", text : "Gender of the Respondent" }, 
      { key : "fld_educat_respondnt_id", text : " Education ID" }, 
      { key : "fld_educat_respondnt_name", text : "Education of the Respondent" }, 
      { key : "fld_maritlstats_respondnt_id", text : " Marital Status ID" },
      { key : "fld_maritlstats_respondnt_name", text : "Marital Status of the respondent" },
      { key : "fld_family_membrs", text : "Total Family members" }, 
      { key : "fld_type_family_id", text : "Type of family ID " }, 
      { key : "fld_type_family_name", text : "Type of family" }, 
      { key : "fld_religin_id", text : "Religion ID" },
      { key : "fld_religin_name", text : "Religion" },
      { key : "fld_religin_others_specify", text : "Others specify" }, 
      { key : "fld_cast_id", text : "Caste ID" }, 
      { key : "fld_cast_name", text : "Caste" }, 
    ],
   
    tab4Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_member_id", text : "Member ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_name", text : "Name" },
      { key : "fld_relation_hh_id", text : " Relation With HH ID" }, 
      { key : "fld_relation_hh_name", text : "Relation With HH" }, 
      { key : "fld_relationship_other", text : "Relationship Other" }, 
      { key : "fld_age", text : "Age (completed years)" }, 
      { key : "fld_gender_id", text : "Gender ID" }, 
      { key : "fld_gender_name", text : "Gender" }, 
      { key : "fld_occupation_id", text : "Occupation ID" }, 
      { key : "fld_occupation_name", text : "Occupation" }, 
      { key : "fld_occup_others_specify", text : "Others specify" }, 
      { key : "fld_monthly_income", text : "Monthly Income (INR..... /month)" }, 
      { key : "fld_mem_scrend_for_chronic_dis_id", text : "Chronic disease ID)" }, 
      { key : "fld_mem_scrend_for_chronic_dis_name", text : "Member screened for any chronic diseases in the last 6 months. (Above18 years)?" }, 
      { key : "fld_any_fam_mem_chronic_dis_id", text : "family member have chronic disease ID " }, 
      { key : "fld_any_fam_mem_chronic_dis_name", text : "Whether the member have chronic disease?" }, 
      { key : "fld_spcfy_typ_chron_dis_id", text : "Specify type of Chronic disease ID " }, 
      { key : "fld_spcfy_typ_chron_dis_name", text : "Specify type of Chronic disease" }, 
      { key : "fld_others_specify", text : "Others specify" }, 
      { key : "fld_mem_above_18_years_id", text : "If any HH member above 18 ID" }, 
      { key : "fld_mem_above_18_years", text : "If any HH member above 18 years is not screened, specify as ‘Eligible Not Screened’ (ENS)’" },  
    ],
    tab5Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_ownership_house_id", text : "Ownership of house ID " },
      { key : "fld_ownership_house_name", text : "Ownership of house" },
      { key : "fld_typ_housing_structre_id", text : "Type of housing structure ID " },
      { key : "fld_typ_housing_structre_name", text : "Type of housing structure" },
      { key : "fld_main_sourc_drink_water_id", text : " Main source of drinking water ID" },
      { key : "fld_main_sourc_drink_water_name", text : "Main source of drinking water" },
      { key : "fld_Drink_watr_other_specify", text : "Others specify" },
      { key : "fld_make_safe_water_drink_id", text : " Water safe to drink ID ?" },
      { key : "fld_make_safe_water_drink_name", text : "What usually you do to make the water safe to drink?" },
      { key : "fld_toilet_faclty_hh_mem_use_id", text : "Kind of toilet facility ID " },
      { key : "fld_toilet_faclty_hh_mem_use_name", text : "What kind of toilet facility do your household members use?" },
      { key : "fld_tolit_fac_other_specify", text : "Others specify" },
      { key : "fld_do_all_family_mem_use_toilet_id", text : " Do all the family members use the toilet ID" },
      { key : "fld_do_all_family_mem_use_toilet_name", text : "Do all the family members use the toilet (including children)?" },
      { key : "fld_if_no_why_id", text : "If no, why ID " },
      { key : "fld_if_no_why_name", text : "If no, why?" },
      { key : "fld_no_why_other_specify", text : "Others specify" },
      { key : "fld_does_electricity_connection_id", text : "Does your household have electricity connection ID ?" },
      { key : "fld_does_electricity_connection_name", text : "Does your household have electricity connection?" },
      { key : "fld_typ_fuel_for_cooking_id", text : "Type of fuel your household mainly use for cooking ID ?" },
      { key : "fld_typ_fuel_for_cooking_name", text : "What type of fuel does your household mainly use for cooking?" },
      { key : "fld_fule_other_specify", text : "Others specify" },
      { key : "fld_do_u_hav_ration_card_id", text : "Household have ration card ID" },
      { key : "fld_do_u_hav_ration_card_name", text : "Do your household have ration card?" },
      { key : "fld_if_yes_verify_ration_card_id", text : "Verify ration card ID " },
      { key : "fld_if_yes_verify_ration_card_name", text : "If yes, verify ration card" },
      { key : "fld_u_any_family_mem_hav_health_ins_policy_id", text : "Family members have any medical/ health insurance policy ID " },
      { key : "fld_u_any_family_mem_hav_health_ins_policy_name", text : "Do you or any of your family members have any medical/ health insurance policy?" },
      { key : "fld_if_yes_verify_insu_card_id", text : "Verify insurance card ID " },
      { key : "fld_if_yes_verify_insu_card_name", text : "If yes, verify insurance card" },
      { key : "fld_privat_ins_scheme_name", text : "Private insurance scheme name" },
      { key : "fld_privat_in_other_specify", text : "Others specify" },
      { key : "fld_typ_salt_prepare_food_id", text : "Type of salt you use ID " },
      { key : "fld_typ_salt_prepare_food_name", text : "Type of salt you use to prepare food in the house" },
      { key : "fld_transport_facilty_public_trans_bus_id", text : "Public transport-bus ID " },
      { key : "fld_transport_facilty_public_trans_bus_name", text : "Public transport-bus" },
      { key : "fld_transport_facilty_rickshaw_id", text : " Rickshaw ID" },
      { key : "fld_transport_facilty_rickshaw_name", text : "Rickshaw" },
      { key : "fld_transport_facilty_auto_rickshaw_id", text : "Auto rickshaws ID " },
      { key : "fld_transport_facilty_auto_rickshaw_name", text : "Auto rickshaws" },
      { key : "fld_transport_facilty_taxi_van_jeep_id", text : "Taxi/Van/Jeep ID " },
      { key : "fld_transport_facilty_taxi_van_jeep_name", text : "Taxi/Van/Jeep" },
      { key : "fld_transport_facilty_any_other_specfy_id", text : " Any other ID" },
      { key : "fld_transport_facilty_any_other_specfy_name", text : "Any other (Specify)" },
      { key : "fld_transport_facility_other_specify", text : "Other (Specify)" },
      { key : "fld_does_vill_hav_mobil_net_id", text : " Village have mobile network ID" },
      { key : "fld_does_vill_hav_mobil_net_name", text : "Does the village have mobile network" },
      { key : "fld_commnity_bas_org_in_vill_id", text : "CBO in your village ID " },
      { key : "fld_commnity_bas_org_in_vill_name", text : "Community-based organizations (CBO) (refer to the list) in your village" },
      { key : "fld_any_cbo_other_specify", text : "Any other (Specify)" },
      { key : "fld_wht_hapng_area_id", text : "What is happening in your area ID ?" },
      { key : "fld_wht_hapng_area_name", text : "How do you generally come to know about what is happening in your area?" },
      { key : "fld_wht_hapn_others_specify", text : "Others (Specify)" },
      { key : "fld_wht_ent_pop_id", text : "What entertainments are more popular in your village ID ?" },
      { key : "fld_wht_ent_pop_name", text : "What entertainments are more popular in your village?" },
      { key : "fld_entermntt_any_other_specify", text : "Any other art (Specify)" },
      { key : "fld_regulr_sorc_med_care_id", text : "Regular source of medical care for your household ID ?" },
      { key : "fld_regulr_sorc_med_care_name", text : "What is the regular source of medical care for your household?" },
      { key : "fld_other_response_specify", text : "Other response (Specify)" },
      { key : "fld_dist_to_phc_id", text : "Distance to PHC ID " },
      { key : "fld_dist_to_phc", text : "Distance to PHC from your village (In KM)" },
      { key : "fld_phc_distance", text : "Distance PHC (In KM)" },
      { key : "fld_dist_to_shc_frm_village_id", text : "Distance to SHC/HWC ID  " },
      { key : "fld_dist_to_shc_frm_village", text : "Distance to SHC/HWC from your village (In KM)" },
      { key : "fld_shc_distance", text : "Distance SHC (In KM)" },
      { key : "fld_visit_phc_id", text : "You and your family members visit the PHC ID " },
      { key : "fld_visit_phc_name", text : "Do you and your family members visit the PHC for any health issues in the last 1 year?" },
      { key : "fld_reason_not_visit_phc_id", text : "If no, what are the reasons ID " },
      { key : "fld_reason_not_visit_phc_name", text : "If no, what are the reasons for not visiting the PHC?" },
      { key : "fld_visit_phc_any_other_specify", text : "Any other (Specify)" },
      { key : "fld_difficlt_in_reaching_phc_id", text : "Difficulties faced by you and your household members in reaching PHC ID " },
      { key : "fld_difficlt_in_reaching_phc_name", text : "What were the difficulties faced by you and your household members in reaching PHC?" },
      { key : "fld_difficult_faced_any_other_specify", text : "Any other (Specify)" },
    ],
    tab6Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_member_id", text : "Member ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_name", text : "Name" }, 
      { key : "fld_num_hospit_event", text : "Total no. of hospitalization events in the past 12 months" },
      { key : "fld_recent_hospi_illns_detals", text : "Illness details" },
      { key : "fld_recent_hosptl_name_id", text : "Hospital Name ID" }, 
      { key : "fld_recent_hosptl_name_name", text : "Hospital Name" }, 
      { key : "fld_hospital_any_other_specify", text : "Any other (Specify)" }, 
      { key : "fld_totl_amnt_spnt_hosptlzn", text : "Total amount spent for hospitalization? (In Rs)" }, 
      { key : "fld_arrang_money_id", text : "How did you arrange this money ID " },
      { key : "fld_arrang_money_name", text : "How did you arrange this money?" },
      { key : "fld_arrang_any_other_specify", text : "Any other (Specify)" },
      { key : "fld_did_covr_expnd_helth_schm_id", text : "Expenditure under any health scheme ID ? "},
      { key : "fld_did_covr_expnd_helth_schm_name", text : "Did you cover this expenditure under any health scheme? "},
      { key : "fld_name_schme", text : "If yes,write the name of the scheme" },
    ],
   
    tab7Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_member_id", text : "Member ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_name", text : "Name" },
      { key : "fld_details_chron_ill_id", text : "Details of chronic illness ID" },
      { key : "fld_details_chron_ill_name", text : "Details of chronic illness" },
      { key : "fld_Cronic_ill_other_specify",text:"Other specify"},
      { key : "fld_since_how_mny_years",text:"Since how many years"},
      { key : "fld_source_trtment_id",text:"Sources of treatment ID "},
      { key : "fld_source_trtment_name",text:"Sources of treatment"},
      { key : "fld_sourc_tret_any_other_specify",text:"Any other(Specify)"},
      { key : "fld_mny_spnt_for_illnss",text:"Money spent on this illness for medicines per month"},
      { key : "fld_major_expnd_fr_illnss",text:"Any major expenditures over the past 12 months for this illness (other than medicines)"},
      { key : "fld_arrang_money_id",text:"How did you arrange for this money ID ?"},
      { key : "fld_arrang_money_name",text:"How did you arrange for this money?"},
      { key : "fld_arrang_mony_any_other_specify",text:"Any other(Specify)"},
      { key : "fld_expnd_covr_helth_schem_id",text:"Did you cover this expenditure under any health scheme ID ?"},
      { key : "fld_expnd_covr_helth_schem_name",text:"Did you cover this expenditure under any health scheme?"},
      { key : "fld_name_schme",text:"If yes,write the name of the scheme"},
    ],
    tab8Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" },
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_member_id", text : "Member ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_name", text : "Name" }, 
      { key : "fld_bp_duaration_id", text : "Since how long you have BP ID ?" }, 
      { key : "fld_bp_duaration_name", text : "Since how long do you have BP?" }, 
      { key : "fld_num_of_years", text : "No of years" }, 
      { key : "fld_lst_scrnd_hypertnsn_id", text : "Last time you were screened for hypertension ID ?" },
      { key : "fld_lst_scrnd_hypertnsn_name", text : "When was the last time you were screened for hypertension?" },
      { key : "fld_lst_time_bp_what_id", text : "What was your BP last time ID ?" }, 
      { key : "fld_lst_time_bp_what_name", text : "What was your BP last time?(Verify the medical card)" }, 
      { key : "fld_bp_value", text : "BP Value (In mmHg)" }, 
      { key : "fld_screning_plac_lst_tim_id", text : "Place of Screening of last time ID" },
      { key : "fld_screning_plac_lst_tim_name", text : "Place of Screening of last time" },
      { key : "fld_screen_place_other_specify", text : "Others Specify" }, 
      { key : "fld_doc_vist_bp_scrning_id", text : "How often do you visit doctor for followup of BP screening ID ?" }, 
      { key : "fld_doc_vist_bp_scrning_name", text : "How often do you visit doctor for followup of BP screening?" }, 
      { key : "fld_reson_not_visit_doc_id", text : " What are the reasons for not visiting the Doctor once in 3 months ID" },
      { key : "fld_reson_not_visit_doc_name", text : "What are the reasons for not visiting the Doctor once in 3 months?" },
      { key : "fld_reason_visit_other_specify", text : "Others Specify" }, 
      { key : "fld_refer_to_faclty_bp_issue_id", text : "Where you referred to any health facility by your doctor for any treatment related to BP issue ID " }, 
      { key : "fld_refer_to_faclty_bp_issue_name", text : "Where you referred to any health facility by your doctor for any treatment related to BP issue?" }, 
      { key : "fld_others_specify", text : "Others specify" },
      { key : "fld_if_yes_where_id", text : "If yes,where ID ?" },
      { key : "fld_if_yes_where", text : "If yes,where?" },
      { key : "fld_visit_refrd_faclty_id", text : "Did you visit the referred facility ID ?" },
      { key : "fld_visit_refrd_faclty_name", text : "Did you visit the referred facility?" },
      { key : "fld_if_no_why", text : "if no why" }, 
      { key : "fld_visit_other_faclty_id", text : "Did you visit any other facility ID ?" }, 
      { key : "fld_visit_other_faclty_name", text : "Did you visit any other facility?" }, 
      { key : "fld_specify_faclty_id", text : " Specify the facility name ID" },
      { key : "fld_specify_faclty_name", text : "Specify the facility name" },
      { key : "fld_follw_hyprtnsn_mgm_id", text : "Where do you prefer being followed up for hypertension management ID " }, 
      { key : "fld_follw_hyprtnsn_mgm_name", text : "Where do you prefer being followed up for hypertension management?" }, 
      { key : "fld_hyprtnsn_other_specify", text : "Others specify" }, 
      { key : "fld_reson_for_prefernc", text : "What is the reason for preference?" },
      { key : "fld_tak_medcn_for_hyprtnsn_id", text : "At present,are you taking any medicines for hypertension ID ?" }, 
      { key : "fld_tak_medcn_for_hyprtnsn_name", text : "At present, are you taking any medicines for hypertension?" }, 
      { key : "fld_resn_for_not_takng_medcn_id", text : "Why are you not taking medicine ID ?" }, 
      { key : "fld_resn_for_not_takng_medcn_name", text : "Why are you not taking medicine?" }, 
      { key : "fld_med_not_tkn_reson_other_spicfy", text : "Others Specify" },
      { key : "fld_typ_medcn_taking_name", text : "If yes, which type of medicine are you taking?"}, 
      { key : "fld_typ_med_other_spicfy", text : "Others Specify" }, 
      { key : "fld_what_medcn_taking", text : "What medicine are you taking?" },
      { key : "fld_what_dosage", text : "What is the dosage? (in mg)" }, 
      { key : "fld_freq_takng_medcn_id", text : "What is the frequency of taking medicine ID ?" }, 
      { key : "fld_freq_takng_medcn_name", text : "What is the frequency of taking medicine?" }, 
      { key : "fld_resn_for_not_takng_medicn_id", text : "Why are you not taking the medicine daily ID ?" },
      { key : "fld_resn_for_not_takng_medicn_name", text : "Why are you not taking the medicine daily?" },
      { key : "fld_med_daly_other_specify", text : "Others Specify" }, 
      { key : "fld_oth_thng_doing_cont_bp_id", text : "What other thing are you doing to control your BP ID ?" }, 
      { key : "fld_oth_thng_doing_cont_bp_name", text : "What other thing are you doing to control your BP?" }, 
      { key : "fld_control_bp_other_specify", text : "Others Specify" },
      { key : "fld_norm_bp_range_id", text : "What is the normal blood pressure range ID ?" }, 
      { key : "fld_norm_bp_range_name", text : "What is the normal blood pressure range?" }, 
      { key : "fld_sign_symp_high_bp_id", text : "What are the signs and symptoms of high blood pressure ID ?" },
      { key : "fld_sign_symp_high_bp_name", text : "What are the signs and symptoms of high blood pressure?" },
      { key : "fld_signsypt_other_specify", text : "Others Specify" }, 
      { key : "fld_causes_high_bp_id", text : "What causes high blood pressure (hypertension) ID ?" }, 
      { key : "fld_causes_high_bp_name", text : "What causes high blood pressure (hypertension)?" }, 
      { key : "fld_hbp_other_specify", text : "Others Specify" },
      { key : "fld_happn_bp_remns_untreatd_id", text : "What can happen if blood pressure remains untreated ID ?" },
      { key : "fld_happn_bp_remns_untreatd_name", text : "What can happen if blood pressure remains untreated?" },
      { key : "fld_bp_other_specify", text : "Others Specify" }, 
      { key : "fld_how_prevnt_hyprtnsn_id", text : "How can one prevent hypertension ID ?" }, 
      { key : "fld_how_prevnt_hyprtnsn_name", text : "How can one prevent hypertension?" }, 
      { key : "fld_hypertnsn_other_specify", text : "Others Specify" },
      { key : "fld_how_mng_hypertsiv_id", text : "How can one manage if they are hypertensive ID ?" },
      { key : "fld_how_mng_hypertsiv_name", text : "How can one manage if they are hypertensive?" },
      { key : "fld_how_mng_hypertsiv_other", text : "Others Specify" }, 
      { key : "fld_sourc_awarnss_hypertnsn_id", text : "What is the source of awareness about hypertension ID ?" }, 
      { key : "fld_sourc_awarnss_hypertnsn_name", text : "What is the source of awareness about hypertension?" }, 
      { key : "fld_awarn_ht_other_specify", text : "Others Specify" },
    ],
    tab9Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_member_id", text : "Member ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_name", text : "Name" },
      { key : "fld_since_how_long_diabtes_id", text : "Since how long you have Diabetes ID ?" },
      { key : "fld_since_how_long_diabtes_name", text : "Since how long you have Diabetes?" },
      { key : "fld_num_of_years", text : "No of years" },
      { key : "fld_whn_lst_scrnd_for_diabtes_id", text : "When was the last time you were screened for Diabetes ID ?" },
      { key : "fld_whn_lst_scrnd_for_diabtes_name", text : "When was the last time you were screened for Diabetes?" },
      { key : "fld_rang_diabtes_chekd_lst_id", text : "What was your Diabetes range when you checked last time?(Verify Medical Card) ID " },
      { key : "fld_rang_diabtes_chekd_lst_name", text : "What was your Diabetes range when you checked last time?(Verify Medical Card)" },
      { key : "fld_place_scrning_lst_id", text : "Place of Screening of last time ID " },
      { key : "fld_place_scrning_lst_name", text : "Place of Screening of last time" },
      { key : "fld_screening_other_specify", text : "Others Specify" },
      { key : "fld_doc_vist_dibtes_scrning_id", text : "How often do you visit doctor for follow-up of Diabetes screening ID ?" },
      { key : "fld_doc_vist_dibtes_scrning_name", text : "How often do you visit doctor for follow-up of Diabetes screening?" },
      { key : "fld_reson_not_visit_doc_id", text : "What are the reasons for not visiting the Doctor once in 3 months ID ?" },
      { key : "fld_reson_not_visit_doc_name", text : "What are the reasons for not visiting the Doctor once in 3 months?" },
      { key : "fld_not_visit_doc_other_specify", text : "Others specify" },
      { key : "fld_refer_to_faclty_dibtes_issue_id", text : "Where you referred to any health facility by your doctor for any treatment related to Diabetes issue ID ?" },
      { key : "fld_refer_to_faclty_dibtes_issue_name", text : "Where you referred to any health facility by your doctor for any treatment related to Diabetes issue?" },
      { key : "fld_others_specify", text : "Others specify" },
      { key : "fld_if_yes_where_id", text : "If yes where ID " },
      { key : "fld_if_yes_where", text : "If yes, where?" },
      { key : "fld_visit_refrd_faclty_id", text : "Did you visit the referred facility ID ?" },
      { key : "fld_visit_refrd_faclty_name", text : "Did you visit the referred facility?" },
      { key : "fld_if_no_why", text : "If no ,why?" },
      { key : "fld_visit_other_faclty_id", text : "Did you visit any other facility ID ?" },
      { key : "fld_visit_other_faclty_name", text : "Did you visit any other facility?" },
      { key : "fld_specify_faclty_id", text : "Specify the facility name ID " },
      { key : "fld_specify_faclty_name", text : "Specify the facility name" },
      { key : "fld_follw_dibtes_mgm_id", text : "Where do you prefer being followed up for Diabetes’ management ID ?" },
      { key : "fld_follw_dibtes_mgm_name", text : "Where do you prefer being followed up for Diabetes’ management?" },
      { key : "fld_dibts_other_specify", text : "Others Specify" }, 
      { key : "fld_reson_for_prefernc", text : "What is the reason for preference?" }, 
      { key : "fld_tak_medcn_for_dibates_id", text : "At present, are you taking any medicines for Diabetes ID ?" }, 
      { key : "fld_tak_medcn_for_dibates_name", text : "At present, are you taking any medicines for Diabetes?" }, 
      { key : "fld_resn_for_not_takng_medcn_id", text : "Why are you not taking medicine ID ?" }, 
      { key : "fld_resn_for_not_takng_medcn_name", text : "Why are you not taking medicine?" }, 
      { key : "fld_med_not_tkn_other_spicfy", text : "Others specify" }, 
      { key : "fld_typ_medcn_taking_id", text : "If yes, which type of medicine are you taking ID ?" }, 
      { key : "fld_typ_medcn_taking_name", text : "If yes, which type of medicine are you taking?" }, 
      { key : "fld_med_typ_other_spicfy", text : "Others specify" }, 
      { key : "fld_what_medcn_taking", text : "What medicine are you taking?" }, 
      { key : "fld_what_dosage", text : "What is the dosage? (In Mg)" }, 
      { key : "fld_freq_takng_medcn_id", text : "What is the frequency of taking medicine ID ?" }, 
      { key : "fld_freq_takng_medcn_name", text : "What is the frequency of taking medicine?" }, 
      { key : "fld_freq_med_tak_other_spicfy", text : "Others specify" }, 
      { key : "fld_resn_for_not_consum_medicn_id", text : " What are the reasons for not consuming medicine daily ID?" }, 
      { key : "fld_resn_for_not_consum_medicn_name", text : "What are the reasons for not consuming medicine daily?" }, 
      { key : "fld_reson_med_other_specify", text : "Others specify" }, 
      { key : "fld_oth_thng_doing_cont_dibtes_id", text : "What other thing are you doing to control Diabetes ID ?" }, 
      { key : "fld_oth_thng_doing_cont_dibtes_name", text : "What other thing are you doing to control Diabetes?" }, 
      { key : "fld_cont_diabts_other_specify", text : "Others specify" }, 
      { key : "fld_norm_fast_blod_glucos_level_id", text : "What is the normal fasting blood glucose level ID ?" }, 
      { key : "fld_norm_fast_blod_glucos_level_name", text : "What is the normal fasting blood glucose level?" }, 
      { key : "fld_sign_symp_dibtes_id", text : " What are the signs and symptoms of Diabetes ID?" }, 
      { key : "fld_sign_symp_dibtes_name", text : "What are the signs and symptoms of Diabetes?" }, 
      { key : "fld_sypt_diabt_other_specify", text : "Others specify" }, 
      { key : "fld_causes_dibtes_id", text : "What causes Diabetes ID ?" }, 
      { key : "fld_causes_dibtes_name", text : "What causes Diabetes?" }, 
      { key : "fld_caus_diabt_other_specify", text : "Others specify" }, 
      { key : "fld_complicasn_uncont_dibtes_id", text : "What are the complications associated with uncontrolled Diabetes ID ?" }, 
      { key : "fld_complicasn_uncont_dibtes_name", text : "What are the complications associated with uncontrolled Diabetes?" }, 
      { key : "fld_unctrl_diabtes_other_specify", text : "Others specify" }, 
      { key : "fld_how_prevnt_dibtes_id", text : "How can one prevent Diabetes ID ?" }, 
      { key : "fld_how_prevnt_dibtes_name", text : "How can one prevent Diabetes?" }, 
      { key : "fld_prev_diabts_other_specify", text : "Others specify" }, 
      { key : "fld_how_manage_dibtes_id", text : "How can one manage if they are Diabetes ID ?" }, 
      { key : "fld_how_manage_dibtes_name", text : "How can one manage if they are Diabetes?" }, 
      { key : "fld_manag_dibts_other_specify", text : "Others specify" }, 
      { key : "fld_sourc_awarnss_dibtes_id", text : "What is the source of awareness about Diabetes?" }, 
      { key : "fld_sourc_awarnss_dibtes_name", text : "What is the source of awareness about Diabetes?" }, 
      { key : "fld_awrns_ht_other_specify", text : "Others specify" }, 
    ],
     tab10Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_member_id", text : "Member ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_name", text : "Name" },
      { key : "fld_type_diet_follw_id", text : "Type of diet do you follow ID " }, 
      { key : "fld_type_diet_follw_name", text : "Type of diet do you follow" }, 
      { key : "fld_do_u_eat_folw_meal_id", text : "Do you eat the following meals daily?(Breakfast, Lunch and Dinner) ID " }, 
      { key : "fld_do_u_eat_folw_meal_name", text : "Do you eat the following meals daily?(Breakfast, Lunch and Dinner)" }, 
      { key : "fld_which_meal_miss_id", text : "Which meals do you miss most of the time? ID " }, 
      { key : "fld_which_meal_miss_name", text : "Which meals do you miss most of the time?" }, 
      { key : "fld_why_miss_meal_id", text : "Why do you miss ID ?" }, 
      { key : "fld_why_miss_meal_meal", text : "Why do you miss?" }, 
      { key : "fld_miss_meal_other_reasons", text : "Other reasons" }, 
      { key : "fld_kind_of_diet_restrct_follw_id", text : "What kind of dietary restrictions do you follow ID ?" }, 
      { key : "fld_kind_of_diet_restrct_follw_name", text : "What kind of dietary restrictions do you follow?" }, 
      { key : "fld_diet_restrct_others_specify", text : "Others specify" }, 
      { key : "fld_food_consupsn_cereals_id", text : "Cereals and Millets ID " }, 
      { key : "fld_food_consupsn_cereals_name", text : "Cereals (rice, Jawar, wheat, Ragi) and Millets" }, 
      { key : "fld_food_consupsn_pulses_id", text : "Pulses ID " }, 
      { key : "fld_food_consupsn_pulses_name", text : "Pulses (Dal, Beans, green gram, Bengal gram etc)" }, 
      { key : "fld_food_consupsn_gren_leaf_vegt_id", text : "Green leafy vegetables ID " }, 
      { key : "fld_food_consupsn_gren_leaf_vegt_name", text : "Green leafy vegetables" }, 
      { key : "fld_food_consupsn_other_vegt_id", text : "Other vegetables ID " }, 
      { key : "fld_food_consupsn_other_vegt_name", text : "Other vegetables" }, 
      { key : "fld_food_consupsn_milk_prodct_id", text : "Milk and Milk Products ID  " }, 
      { key : "fld_food_consupsn_milk_prodct_name", text : "Milk and Milk Products (milk, curd, butter, ghee)" }, 
      { key : "fld_food_consupsn_fruits_id", text : "Fruits ID " }, 
      { key : "fld_food_consupsn_fruits_name", text : "Fruits" }, 
      { key : "fld_food_consupsn_egg_id", text : "Eggs ID " }, 
      { key : "fld_food_consupsn_egg_name", text : "Eggs" }, 
      { key : "fld_food_consupsn_meat_chikn_id", text : "Meat/Chicken ID " }, 
      { key : "fld_food_consupsn_meat_chikn_name", text : "Meat/Chicken" }, 
      { key : "fld_food_consupsn_fast_food_id", text : "Fast foods like noodles, pizza, burgers, doughnuts, fried fish, milk shakes, chips, sandwiches etc ID " }, 
      { key : "fld_food_consupsn_fast_food_name", text : "Fast foods like noodles, pizza, burgers, doughnuts, fried fish, milk shakes, chips, sandwiches etc" }, 
      { key : "fld_food_consupsn_soft_drink_id", text : "Soft/cold drinks like coco cola, Sprite, Thumps up and other aerated drinks ID " }, 
      { key : "fld_food_consupsn_soft_drink_name", text : "Soft/cold drinks like coco cola, Sprite, Thumps up and other aerated drinks" }, 

    ],
    tab11Data:[
      { key : "slNo", text : "SlNo" },
      { key : "fld_loggedin_user_id", text: "User ID" },
      { key : "fld_date_of_interview", text : "Date Of Interview" }, 
      { key : "fld_interviewer_name", text : "Name of the Interviewer" }, 
      { key : "fld_hh_id", text : "Household ID" },
      { key : "fld_member_id", text : "Member ID" },
      { key : "fld_state_code", text : "State code" },
      { key : "fld_state_name", text : "State name" },
      { key : "fld_district_code", text : "District code" },
      { key : "fld_district_name", text : "District name" },
      { key : "fld_taluk_code", text : "Taluk code" },
      { key : "fld_taluk_name", text : "Taluk name" },
      { key : "fld_block_code", text : "Block code" },
      { key : "fld_block_name", text : "Block name" },
      { key : "fld_phc_code", text : "PHC code" },
      { key : "fld_phc_name", text : "PHC name" },
      { key : "fld_hsc_hwc_code", text : "HSC/HWC code" },
      { key : "fld_hsc_hwc_name", text : "HSC/HWC name" },
      { key : "fld_panchyt_code", text : "Panchayat code" },
      { key : "fld_panchyt_name", text : "Panchayat name" },
      { key : "fld_village_code", text : "Village code" },
      { key : "fld_village_name", text : "Village name" },
      { key : "fld_name", text : "Name" }, 
      { key : "fld_oth_hlth_prob_nothng_id", text : "Nothing ID " }, 
      { key : "fld_oth_hlth_prob_nothng_name", text : "Nothing" }, 
      { key : "fld_heart_disease_id", text : "Heart Disease ID " }, 
      { key : "fld_heart_disease_name", text : "Heart Disease" }, 
      { key : "fld_yes_taking_hd_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_hd_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_hd_id", text : "Place of treatment ID " }, 
      { key : "fld_place_of_trtmnt_hd_name", text : "Place of treatment" }, 
      { key : "fld_hd_pt_hd_other_specify", text : "Others Specify" }, 
      { key : "fld_stroke_id", text : "Stroke ID " },
      { key : "fld_stroke_name", text : "Stroke" },
      { key : "fld_yes_taking_stok_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_stok_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_stok_id", text : "Place of treatment ID " }, 
      { key : "fld_place_of_trtmnt_stok_name", text : "Place of treatment" }, 
      { key : "fld_strok_pt_stok_other_specify", text : "Others Specify" }, 
      { key : "fld_asthma_id", text : "Asthma ID " },
      { key : "fld_asthma_name", text : "Asthma" },
      { key : "fld_yes_taking_astma_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_astma_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_astma_id", text : "Place of treatment ID " }, 
      { key : "fld_place_of_trtmnt_astma_name", text : "Place of treatment" }, 
      { key : "fld_asthma_pt_astma_other_specify", text : "Others Specify" },
      { key : "fld_pcos_id", text : "PCOS ID " }, 
      { key : "fld_pcos_name", text : "PCOS" }, 
      { key : "fld_yes_taking_pcos_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_pcos_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_pcos_id", text : "Place of treatment ID " },
      { key : "fld_place_of_trtmnt_pcos_name", text : "Place of treatment" },
      { key : "fld_pcos_pt_pcos_other_specify", text : "Others Specify" }, 
      { key : "fld_thyroid_id", text : "Thyroid ID " }, 
      { key : "fld_thyroid_name", text : "Thyroid" }, 
      { key : "fld_yes_taking_thy_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_thy_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_thy_id", text : "Place of treatment ID " },
      { key : "fld_place_of_trtmnt_thy_name", text : "Place of treatment" },
      { key : "fld_thyroid_pt_thy_other_specify", text : "Others Specify" },
      { key : "fld_teeth_or_gum_id", text : " Teeth or gum ID" }, 
      { key : "fld_teeth_or_gum_name", text : "Teeth or gum" }, 
      { key : "fld_yes_taking_tgum_trtmnt_id", text : "If yes, are you taking treatment ID?" }, 
      { key : "fld_yes_taking_tgum_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_tgum_id", text : "Place of treatment ID " },
      { key : "fld_place_of_trtmnt_tgum_name", text : "Place of treatment" },
      { key : "fld_teethgum_pt_tgum_other_specify", text : "Others Specify" }, 
      { key : "fld_retinopathy_id", text : "Retinopathy ID" },
      { key : "fld_retinopathy_name", text : "Retinopathy" },
      { key : "fld_yes_taking_retpty_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_retpty_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_retpty_id", text : "Place of treatment ID " },
      { key : "fld_place_of_trtmnt_retpty_name", text : "Place of treatment" },
      { key : "fld_retino_pt_retpty_other_specify", text : "Others Specify" }, 
      { key : "fld_sexul_dysfunction_id", text : "Sexual dysfunction ID " },
      { key : "fld_sexul_dysfunction_name", text : "Sexual dysfunction" },
      { key : "fld_yes_taking_sexdys_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_sexdys_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_sexdys_id", text : "Place of treatment ID " },
      { key : "fld_place_of_trtmnt_sexdys_name", text : "Place of treatment" },
      { key : "fld_sd_pt_sexdys_other_specify", text : "Others Specify" },
      { key : "fld_other_health_prob_id", text : "Other health problems specify ID " }, 
      { key : "fld_other_health_prob_name", text : "Other health problems specify" }, 
      { key : "fld_yes_taking_othersp_trtmnt_id", text : "If yes, are you taking treatment ID ?" }, 
      { key : "fld_yes_taking_othersp_trtmnt_name", text : "If yes, are you taking treatment?" }, 
      { key : "fld_place_of_trtmnt_othersp_id", text : "Place of treatment ID " }, 
      { key : "fld_place_of_trtmnt_othersp_name", text : "Place of treatment" }, 
      { key : "fld_other_pt_othersp_other_specify", text : "Others Specify" },
      { key : "fld_any_mod_phys_actvt_id", text : "Do you do any of the following moderate physical activities ID ?" },
      { key : "fld_any_mod_phys_actvt_name", text : "Do you do any of the following moderate physical activities?" },
      { key : "fld_mod_phys_actvt_fqcy_hrs_id", text : "If yes, mention frequency and hours of doing these moderate physical activities ID " }, 
      { key : "fld_mod_phys_actvt_fqcy_hrs_name", text : "If yes, mention frequency and hours of doing these moderate physical activities" }, 
      { key : "fld_total_mod_hrs_spnd_week", text : "Total hours spend in a week" },
      { key : "fld_any_vigo_phys_actvt_id", text : "Do you do any of the vigorous physical activities ID ? " },
      { key : "fld_any_vigo_phys_actvt_name", text : "Do you do any of the vigorous physical activities? " },
      { key : "fld_vigo_phys_actvt_fqcy_hrs_id", text : "If yes, mention frequency and hours of doing these vigorous physical activities ID " }, 
      { key : "fld_vigo_phys_actvt_fqcy_hrs_name", text : "If yes, mention frequency and hours of doing these vigorous physical activities" }, 
      { key : "fld_total_vigo_hrs_spnd_week", text : "Total hours spend in a week" }, 
      { key : "fld_attnd_yoga_sesion_id", text : "Have you attended any Yoga & Meditation sessions in the past 3 months ID ?" }, 
      { key : "fld_attnd_yoga_sesion_name", text : "Have you attended any Yoga & Meditation sessions in the past 3 months?" }, 
      { key : "fld_atnd_where", text : "If yes, where you attended?" },
      { key : "fld_at_present_do_you_have_the_following_habits_id", text : " At present, do you have the following habits ID?" },
      { key : "fld_at_present_do_you_have_the_following_habits_name", text : "At present, do you have the following habits??" },
      { key : "fld_habit_drink_alcho_id", text : "Drinking alcohol ID " },
      { key : "fld_habit_drink_alcho_name", text : "Drinking alcohol" },
      { key : "fld_drink_alcho_fqcy_hrs_id", text : "If yes, specify the frequency ID " },
      { key : "fld_drink_alcho_fqcy_hrs_name", text : "If yes, specify the frequency" },
      { key : "fld_habit_smok_tobco_id", text : "Smoking ID " }, 
      { key : "fld_habit_smok_tobco_name", text : "Smoking" }, 
      { key : "fld_smok_tobco_fqcy_hrs_id", text : "If yes, specify the frequency ID " }, 
      { key : "fld_smok_tobco_fqcy_hrs_name", text : "If yes, specify the frequency" }, 
      { key : "fld_habit_smls_tobco_id", text : "Use of smokeless tobacco ID " }, 
      { key : "fld_habit_smls_tobco_name", text : "Use of smokeless tobacco" }, 
      { key : "fld_smls_tobco_fqcy_hrs_id", text : "If yes, specify the frequency ID " },
      { key : "fld_smls_tobco_fqcy_hrs_name", text : "If yes, specify the frequency" },
      { key : "fld_habit_of_consuming_the_following_in_the_past_id", text : "Did you have the habit of consuming the following in the past ID ?" },
      { key : "fld_habit_of_consuming_the_following_in_the_past_name", text : "Did you have the habit of consuming the following in the past?" },
      { key : "fld_pst_habit_drink_alcho_id", text : "Drinking alcohol ID " },
      { key : "fld_pst_habit_drink_alcho_name", text : "Drinking alcohol" },
      { key : "fld_pst_drink_alcho_fqcy_hrs_id", text : "If yes, specify the frequency ID " },
      { key : "fld_pst_drink_alcho_fqcy_hrs_name", text : "If yes, specify the frequency" },
      { key : "fld_pst_habit_smok_tobco_id", text : " Smoking ID" },
      { key : "fld_pst_habit_smok_tobco_name", text : "Smoking" },
      { key : "fld_pst_smok_tobco_fqcy_hrs_id", text : "If yes, specify the frequency ID" },
      { key : "fld_pst_smok_tobco_fqcy_hrs_name", text : "If yes, specify the frequency" },
      { key : "fld_pst_habit_smls_tobco_id", text : "Use of smokeless tobacco ID" }, 
      { key : "fld_pst_habit_smls_tobco_name", text : "Use of smokeless tobacco" }, 
      { key : "fld_pst_smls_tobco_fqcy_hrs_id", text : "If yes, specify the frequency ID" }, 
      { key : "fld_pst_smls_tobco_fqcy_hrs_name", text : "If yes, specify the frequency" }, 
      { key : "fld_will_partcpet_prgm_id", text : "Are you willing to participate in the program ID?" }, 
      { key : "fld_will_partcpet_prgm_name", text : "Are you willing to participate in the program?" }, 
      { key : "fld_own_mobile_id", text : "Do you own a mobile ID?" },
      { key : "fld_own_mobile_name", text : "Do you own a mobile?" },
      { key : "fld_spicfy_mob_id", text : "If yes, specify the mobile number ID" }, 
      { key : "fld_spicfy_mob_num", text : "If yes, specify the mobile number" }, 
      { key : "fld_type_mob_own_id", text : " What type of mobile do you own ID?" },
      { key : "fld_type_mob_own_name", text : "What type of mobile do you own?" },
      { key : "fld_fm_own_mob_id", text : "Do any of your family member own a mobile ID?" },
      { key : "fld_fm_own_mob_name", text : "Do any of your family member own a mobile?" },
      { key : "fld_specfy_mob_num_rcv_call", text : "If yes, specify the mobile number to which you would like to receive the message/call?" },
      { key : "fld_fam_mob_typ_id", text : "What type of mobile do your family member own ID?" },
      { key : "fld_fam_mob_typ_name", text : "What type of mobile do your family member own?" },
      { key : "fld_pref_time_call_msg", text : "What is the preferred/right time to send a message/call you?" }, 
    ],
   
  }

  const downloadCSV = () => {
    const allData = [
      { tabName: 'HH Consent Form', data: tab1Data, headers: excelHeaders.tab1Data },
      { tabName: 'Respondent detail Form', data: tab2Data, headers: excelHeaders.tab2Data },
      { tabName: 'GI-House Hold Detail', data: tab3Data, headers: excelHeaders.tab3Data },
      { tabName: 'Family Details', data: tab4Data, headers: excelHeaders.tab4Data },
      { tabName: 'Standard of living', data: tab5Data, headers: excelHeaders.tab5Data },
      { tabName: 'HOSPITALIZATION', data: tab6Data, headers: excelHeaders.tab6Data },
      { tabName: 'CHRONIC illnesses', data: tab7Data, headers: excelHeaders.tab7Data },
      { tabName: 'Hypertension', data: tab8Data, headers: excelHeaders.tab8Data },
      { tabName: 'Diabetes', data: tab9Data, headers: excelHeaders.tab9Data },
      { tabName: 'Diet and Nutrition', data: tab10Data, headers: excelHeaders.tab10Data },
      { tabName: 'Health Problems', data: tab11Data, headers: excelHeaders.tab11Data },
    ];

    const wb = XLSX.utils.book_new();

    allData.forEach(({ tabName, data, headers }) => {
      const headerTexts = headers.map(header => typeof header === 'object' ? header.text : header);
      const keys = headers.map(header => typeof header === 'object' ? header.key : header);

      const wsData = data.map(row => keys.map(key => row[key]));

      wsData.unshift(headerTexts);

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      XLSX.utils.book_append_sheet(wb, ws, tabName);
    });

    XLSX.writeFile(wb, `IIHMR_Raw_data_${downloadDate}.xlsx`);
    toast.current.show({
      severity: "success",
      summary: "Successfully downloaded",
      detail: "Raw data downloaded",
      life: 2000, 
      closable: true,
      sticky: false,
    });
  };
  return (
    <div className="m-5">
      {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}
      <h1 className="heading ">Raw Data Report</h1>
      <Toast ref={toast} position="top-right" />
      <Divider/>
      <div className="mt-5 ml-2">
        <Button
        icon="pi pi-download"
        label="Download CSV"
        className="csvDownloadbutton"
        onClick={downloadCSV}
        />
      </div>

      </div>
  );
}

